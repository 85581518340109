<template>
  <div>
    <p>Welcome to the second half or our exclusive interview with Burkhard Schlömer and Duane Hess, two pro designers who are building furiously to help fill LEGO Universe with amazing LEGO models for its launch!
    </p>
    <p>
      If you missed the first installment, <router-link :to="{ name: 'story', params: {id: 129539} }">check it out</router-link> to discover whether Duane and Burkhard prefer pirates or ninjas!
    </p>
    <p>
      <strong>Hi again, Duane and Burkhard! What’s been challenging about becoming model designers for LEGO Universe?</strong>
    </p>
    <p>
      <strong>Burkhard:</strong>
      At first it was tough to build outside of my comfort zone. Imagine specializing in building spaceships and then being asked to create models like LEGO castles!
    </p>
    <p>
      <strong>Duane:</strong>
      Designing models for LEGO Universe pushes the limits of my creativity too, but I’m enjoying immersing myself in the intricate design of the LEGO elements. Since I started building for the game, my arsenal of techniques has expanded so much that now I can build things I could only dream of before!
    </p>
    <p>
      <strong>Tell us a bit about the challenges you overcame to build one of the models you’ve designed for LEGO Universe!</strong>
    </p>
    <p>
      <strong>Duane:</strong>
      As with every model I’ve built for LEGO Universe, a big challenge of designing this ninja gateway was to build an interesting creation that uses as few polygons on a computer as possible.
    </p>
    <!-- MISSING: pic5CEF60DCFCD02303997CF9B8CE39C060.jpg -->
    <img class="rounded" src="">
    <p>
      <strong>Burkhard:</strong>
      Polygon preservation also played into my design decisions when I built this LEGO Universe airship. For instance, it was a challenge to make such a smooth underside on the boat hull.
    </p>
    <p>
      <strong>Duane:</strong>
      Yeah, getting good shapes can be tough. Designing the dragonhead alone took about half of the time I spent on this model, but I enjoy building technically complex models that take a lot of different parts and techniques to get just right.
    </p>
    <!-- MISSING: pic5200B328C07EA5F02F8E031059F5B651.jpg -->
    <img class="rounded" src="">
    <p>
      <strong>Burkhard:</strong>
      I have a lot of fun using different techniques and LEGO elements too! Rather than building with bricks and plates, I used specialized elements and SNOT techniques to create the airship’s balloon.
    </p>
    <p>
      <em>Note: If you’d like more explanation about what “polygons” are, check out part 3 of <router-link :to="{ name: 'story', params: {id: 91070} }">“Create digital designs the LEGO Universe way!”</router-link></em>
    </p>
    <p>
      <strong>What’s your favorite LEGO element? Why?</strong>
    </p>
    <!-- MISSING: picD2A0B6A9AB205B2C17077E923E114CCC.jpg -->
    <img class="leftimage rounded w-100 h-75" src="">
    <p>
      <strong>Duane:</strong>
      That’s a difficult question because there are so many useful LEGO elements, but I tend to use the 1x1 brick with studs on four sides in a lot of my creations. Its shape lends itself to tons of different SNOT techniques.
    </p>
    <!-- MISSING: pic91BDF038D3517C116E081ACD08299E26.jpg -->
    <img class="leftimage rounded w-100 h-75" src="">
    <p>
      <strong>Burkhard:</strong>
      The 1x1 slope because it’s versatile and fits well with my style of building!
    </p>
    <p>
      <strong>Thanks guys! We’re looking forward to seeing more of your cool LEGO creations when LEGO Universe launches in 2010!</strong>
    </p>
    <p>
      Lock your web browser right here on the LEGO Universe News Network for the latest on the first Massively Multiplayer Online Game (MMOG) for LEGO fans!
    </p>
  </div>
</template>
